import { Divider } from "@adobe/react-spectrum";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import { ReactCompareSlider } from "react-compare-slider";
import PluginsLayout from "../../components/PluginsLayout";
import "../../styles/carousel.css";
// import DusmPanelSVG from "../../images/dusm-panel";
import PluginInfoCard from "../../components/PluginInfoCard";
import { PluginContext } from "../../contexts/PluginContext";
import DusmPanelSVG from "../../components/DusmPanelSVG";
import SEO from "../../components/seo";
import SchemaORG from "../../data/dusm-schema.json";
import pluginsArray from "../../data/plugins.json"


const WellContainer = styled.div`
  /* display: flex; */
  /* flex-direction: column;
  align-items: center; */
  /* position: relative; */
  width: 100%;
  text-align: center;

  .plugin-banner {
    border-radius: 8px;
    margin: 0 auto;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  }

  @media (min-width: 900px) {
    font-size: var(--spectrum-global-dimension-font-size-400) !important;
    .banner-well {
      box-shadow: inset 0 1px 6px rgba(0, 0, 0, 0.2),
        0 1px 0 rgba(255, 255, 255, 0.1);
      border-radius: 8px;
      max-width: 900px;
      margin: 0 auto;
      background-color: var(--spectrum-global-color-gray-75);
      padding-top: 10px;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 40px;
    }
  }
`;

const VideoContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
`;

const DusmPlugin = () => {
  const { DusmBanner, CarouselFiles, BeforeAfterFiles } = useStaticQuery(
    graphql`
      query {
        DusmBanner: file(
          relativePath: { eq: "highlights/dusm-highlight.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 700) {
              ...GatsbyImageSharpFluid_withWebp
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }

        CarouselFiles: allFile(
          filter: { relativeDirectory: { eq: "dusm-carousel" } }
        ) {
          nodes {
            id
            childImageSharp {
              fluid(maxWidth: 1000, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
        }
        BeforeAfterFiles: allFile(
          filter: { relativeDirectory: { eq: "dusm-beforeafter" } }
        ) {
          nodes {
            id
            childImageSharp {
              fluid(maxWidth: 900, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
        }
      }
    `
  );
  const { activePlugin, setActivePlugin } = useContext(PluginContext);
  useEffect(() => {
    if (activePlugin !== "double-usm") {
      setActivePlugin("double-usm");
    }
  });
  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: `0`,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const pluginInfoCardSettings = {
    logo: "DUSM-SVG",
    logoColor: "var(--spectrum-global-color-orange-400)",
    title: "Double USM",
    version: pluginsArray.filter(plugin => plugin.title === "Double USM")[0].version,
    price: "26.00",
    link:
      "https://adobe.com/go/cc_plugins_discover_plugin?pluginId=78c3cdaf&workflow=share",
  };

  return (
    <PluginsLayout>
      <SEO
        title="Double USM for Photoshop"
        description="Double USM, Multi-radius Sharpening plugin for Photoshop"
        image="https://www.cc-extensions.com/img/twitter-dusm.jpg"
        slug="plugins/double-usm"
        schema={SchemaORG}
      />
      <WellContainer>
        <div className="banner-well">
          <h1 className="plugin">Double USM</h1>
          <h2
            className="plugin"
            style={{ color: `var(--spectrum-global-color-orange-400)` }}
          >
            Image Sharpening Double Control
          </h2>
          <Img
            fluid={DusmBanner.childImageSharp.fluid}
            className="plugin-banner"
          />
        </div>
      </WellContainer>

      <div style={{ textAlign: `left`, maxWidth: `900px` }}>
        <h3 className="plugin" style={{ marginBottom: `10px` }}>
          In short
        </h3>
        <Divider size="M" />
        <p>
          Double USM is about Sharpening, i.e. all the tricks we use to turn
          blurry images into sources of glorious detail. All sharpening filters
          in Photoshop inject artificial halos but can&rsquo;t discriminate
          between dark and light ones – they&rsquo;re created equal.
          Not-so-cool, as Photoshop Hall of Fame&rsquo;s Dan Margulis found out
          pooling hundreds of professionals: light halos are the main
          <i>&ldquo;oversharpening&rdquo;</i> cause. Double USM to the rescue.
          You&rsquo;re allowed to{" "}
          <em>
            control Amount, Radius and Threshold for Dark and Light Halos
            independently
          </em>
          . Better, sharper images!
        </p>
        <div
          style={{
            display: `grid`,
          }}
        >
          {/* https://react-slick.neostack.com/docs/example/resizable */}
          <Slider {...carouselSettings}>
            {CarouselFiles.nodes.map(({ childImageSharp, id }) => (
              <div key={id}>
                <Img
                  fluid={childImageSharp.fluid}
                  style={{ borderRadius: `6px` }}
                />
              </div>
            ))}
          </Slider>
        </div>

        <Divider size="M" marginTop="size-500" marginBottom="size-300" />

        <DusmPanelSVG style={{ width: `100%` }} />

        <h3 className="plugin" style={{ marginBottom: `10px` }}>
          More about Sharpening
        </h3>
        <Divider size="M" />
        <p>
          Everything you need to know about halos in this short and
          light-hearted video that I&rsquo;ve recorded for the previous version
          – concepts still apply 100%.
        </p>
        <VideoContainer style={{ marginBottom: `40px` }}>
          <iframe
            className="responsive-iframe"
            title="Double USM video"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/G-sO5rKc2B0"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{ marginLeft: `auto`, marginRight: `auto` }}
          />
        </VideoContainer>

        <h3 className="plugin" style={{ marginBottom: `10px` }}>
          Before/After
        </h3>
        <Divider size="M" />
        <p>
          Forgive me for being heavy-handed, it&rsquo;s hard to appreciate the
          level of increased Sharpening on a website.
        </p>

        <ReactCompareSlider
          itemOne={
            <Img fluid={BeforeAfterFiles.nodes[0].childImageSharp.fluid} />
          }
          itemTwo={
            <Img fluid={BeforeAfterFiles.nodes[1].childImageSharp.fluid} />
          }
        />
        {/* <Divider size="M" marginTop="size-300" marginBottom="size-300" /> */}
        <h3 className="plugin" style={{ marginBottom: `10px` }}>
          Buy the Plugin
        </h3>
        <Divider size="M" />
        <p>
          Are you ready to step-up your Sharpening game? With your purchase
          you&rsquo;re helping me (feeding my family and) developing more
          plugins, thank you!
        </p>
        <PluginInfoCard
          style={{ margin: `50px auto` }}
          pluginInfo={pluginInfoCardSettings}
        />
      </div>
    </PluginsLayout>
  );
};

export default DusmPlugin;
